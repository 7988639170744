/* eslint-disable no-irregular-whitespace */
module.exports = {
  language: {
    name: "EN",
    shot_name: "cn",
  },
  title: "畅行智驾 | 畅行佑道 智驾领跑",
  topbar: {
    home: "首页",
    productions: "产品中心",
    productions_razor_dcx: "域控制器 RazorDCX",
    productions_razor_ware_x: "平台软件 RazorWareX",
    productions_razor_aikit: "配套产品",
    productions_dev_kit: "开发套件",
    news: "头条资讯",
    about_us: "关于我们",
    join_us: "加入我们",
  },

  footer: {
    logo_labe: `畅 行 佑 道  ， 智 驾 领 跑`,

    company_production: "公司产品",
    razor_dcx: "域控制器 RazorDCX",
    razor_ware_x: "平台软件 RazorWareX",

    about_us: "关于我们",
    company_introduction: "公司简介",
    company_vision: "使命愿景",
    company_process: "发展历程",
    contact_us: "联系我们",

    join_us: "加入我们",
    social_recruitment: "社会招聘",
    campus_recruitement: "校园招聘",
    internal_recruitement: "实习生招聘",
    social_media: "社交媒体",

    icp_number: "Copyright © 2022-2025 畅行智驾 苏ICP备2025158457号",
    net_icp_number: "沪公网安备 31011402003368号",
  },

  home: {
    advantage_title: `畅行佑道 智驾领跑`,
    advantage_txt: "深入探索和布局智能驾驶系统，高度聚焦于智能驾驶域控与大算力计算平台的商业化落地",
    adv_science_tech: "科技",
    adv_smart: "智能",
    adv_open: "开放",

    join_us: "加入我们",
    vendor: "国际领先的智能汽车计算平台供应商",

    ecology_ablity: "生态能力",
    ecology_ablity_desc:
      "借助中科创达拔尖的软件能力，与主流芯片厂商的深度合作，\n以及对产业链上下游合作伙伴的整合，为下一代出行方式做出贡献",
    precise_local: "精准定位",
    precise_local_desc: "以中算力智能驾驶域控产品为切入点，同步构建多平台战略，\n 打造高性价比智驾产品，成为国内领先的智能驾驶Tier1",
    precede_tech: "技术领先",
    precede_tech_desc:
      "拥有车规级域控制器硬件设计能力、成熟的中间件技术及其工具链、完整的感知算法部署，提供行业领先的智能驾驶及舱驾一体平台",
    bussiness_model: "商业模式",
    bussiness_model_desc: "首创“域控制器+”合作模式，根据客户需求做交付模式定制化，\n 保证客户技术主导权与独立性",

    pro_razor_dcx_tit: "域控制器RazorDCX",
    pro_razor_warex_tit: "平台软件RazorWareX",
    pro_razor_dcx_desc:
      "RazorDCX是集成环境感知、信息融合、决策规划、驱动执行功能的集中式智能驾驶决策控制器，是智能驾驶策略开发与实现的车载高性能实时计算平台。",
    pro_razor_warex_desc:
      "RazorWareX为智能驾驶应用算法提供多种服务，在域控器各芯片间和车端各系统之间配置了高实时性、高宽带的通信骨干网，充分调配硬件算力，共享传感器数据，提供给AI算法运行环境，为智能驾驶提供高可靠性的开放软件平台。",
    pro_desc_btn: "了解详情",
  },

  news: {
    bg_title: "头条资讯",
    bg_tip: "HEADLINES  NEWS",
  },

  pro: {
    razordcx: {
      name: "域控制器RazorDCX",
      description:
        "RazorDCX是集成环境感知、信息融合、决策规划、驱动执行功能的集中式智能驾驶域控制器，是智能驾驶策略开发与实现的车载高性能实时计算平台。",

      perform_a: "高算力：AI算力50TOPS-2000TOPS CPU算力 200-500kDMIPS ",
      perform_b: "ASIL-D高功能安全等级",
      perform_c: "支持12路相机接入，最高8M-Pixels相机",
      perform_d: "支持全时行泊一体，最高L2.9+ ",
      perform_e: "NOA无需高精地图及定位",

      pro_name: "RazorDCX Takla",
      pro_desc: "面向智能驾驶开发的域控制器，可以应用在乘用车、商用车驾驶辅助、封闭园区自动驾驶等应用场景",

      pro_btn: "了解详情",
    },

    razorwarex: {
      name: "平台软件RazorWareX",
      description:
        "RazorWareX为智能驾驶应用算法提供多种服务，在域控器各芯片间和车端各系统之间配置了高实时性、高宽带的通信骨干网，充分调配硬件算力，共享传感器数据，提供给AI算法运行环境，为智能驾驶提供高可靠性的开放软件平台。",

      perform_a: "高实时性",
      perform_b: "高确定性",
      perform_c: "高功能安全",
      perform_d: "端到端延时保证",
      perform_e: "敏感数据延时最低",

      pro_name: "RazorWareX 1.0",
      pro_desc: "智能驾驶场景专用的高功能安全、高实时、确定性中间件平台",

      pro_btn: "了解详情",
    },
    // 张伟20240516
    tongass: {
      name: "RazorDCX Tongass",
      description:
        "RazorDCX Tongass是畅行智驾新一代量产级舱泊一体域控解决方案，该方案采用了高通工艺制程为4nm的全新座舱平台车规级芯片骁龙8255。该芯片具备高达230K的CPU算力、以及最高配置46 TOPs的AI算力。作为第三代旗舰级骁龙座舱平台骁龙8155的升级版，骁龙8255能够提供更加流畅、逼真的图形效果，更加智能的交互体验，以及更加出色的影像处理能力。辅以中科创达/畅行智驾研发团队对座舱领域深入的理解，可向汽车制造商提供兼具高性能和成本优势的舱泊一体域控解决方案。硬件能力方面，基于不同配置，单芯片CPU算力可达189K~230K，AI算力可达10~46TOPS；采用了强劲的SerDes芯片，极限可同时支持12路Camera并发接入；可支持车内多屏显示，理论峰值可达12块；预留了多路CAN/-CANFD 接口，提供车规级千兆以太网接口，为各类传感器提供了更灵活的接入可能性。",

      perform_a: "强可拓展性",
      perform_b: "高功能安全",
      perform_c: "突破性的融合体验",
   

      advantage: {
        adv_a_title: "强可拓展性",
        adv_a_desc: `- 基于高通第四代芯片平台的最新工艺芯片生态，通过设计调整，可实现智驾域、座舱域、融合域的结合与切换，可衍生出更多可能性的域控解决方案。`,
        adv_b_title: "高功能安全",
        adv_b_desc: `- 遵循最严苛的ASIL-D开发标准，采用先进的fail-safe和fail-operational安全架构。`,
        adv_c_title: "突破性的融合体验",
        adv_c_desc: `- 结合滴水OS Pre 1.0 8255座舱版，座舱功能方面，可为用户提供更佳的VGUI生成式自然交互体验，全场景智能视觉感知，以及场景化沉浸式3D渲染效果。除此之外，Tongass可集成APA、RPA等泊车功能，为用户带来自由平滑切换，舱泊融合一体的全新驾驶体验。`,
        // adv_d_title: "强大的AI工具链",
        // adv_d_desc: `RazorDCX Takla继承手机、平板等移动平台，支持所有主流的AI框架`,
        // adv_e_title: "灵活的合作方式",
        // adv_e_desc: `RazorDCX Takla采用算法、软件、硬件相互独立解耦的设计，模块非强绑定强关联，提供给客户灵活多样的合作模式。`,
      },

      core_property: {
        title: "核心参数",
        camera_interface: {
          name: "芯片",
          value: "SA8255P",
        },
        display_interface: {
          name: "典型特征",
          value: `一芯多屏（最多支持12个屏，最高可支持8K屏）\n
多系统：仪表（Linux/QNX）+其他屏（Android）\n
智能视觉：AVM+DMS/FACE ID+DVR+OMS+CMS \n
泊车：APA/RPA/HPA \n
互联：1 蓝牙电话+ 7路手柄 \n
HMI：动态3D HMI界面、一镜到底沉浸式体验 \n
游戏娱乐：多游戏同时运行`,
        },
        sense_input: {
          name: "芯片算力",
          value: `GPU: 1.5TFLOPS\n
CPU: 170kDMIPS\n
AI: 20TOPS`,
        },
        ethernet: {
          name: "关键参数",
          value: `MIPI：4x 4lane, CSI-2 v2.1 \n
USB：2*USB3.1, 1*USB2.0 \n
PCLe：2x PCIe Gen4 – 1x4 + 1x2 lanes \n
Max Memory Size：36GB \n
LPDDR：LPDDR5 \n
工艺制程：5nm`,
        },
        
      },

      nav: {
        summarize: "概述",
        advantage: "优势",
        property: "参数",
        coopearation: "合作咨询",
      },
    },
    // 张伟-20240515
    congo: {
      name: "RazorDCX Congo",
      description:
        "RazorDCX Congo是畅行智驾最新一代极致性价比智能驾驶域控解决方案，可满足低成本智驾域控的需求。Congo采用高通新一代Snapdragon　Ride平台量产级智驾芯片，单芯片算力达36TOPS/80KDMIPS，可同时支持基础配置5V5R12USS与高阶配置7V5R12USS接入，并预留多路CAN/CANFD 接口，提供4路车规级以太网接口，满足不同等级智能驾驶对传感器接入的需求。",

      perform_a: "极致性价比",
      perform_b: "高功能安全",
      perform_c: "完整的智驾研发工具链",
      perform_d: "丰富的功能及应用场景", 

      advantage: {
        adv_a_title: "极致性价比",
        adv_a_desc: `- 支持风冷的散热设计，降低成本、单芯片价格更为经济。整体方案极具性价比。`,
        adv_b_title: "高功能安全",
        adv_b_desc: `- 遵循最严苛的ASIL-D开发标准，采用先进的fail-safe和fail-operational安全架构。`,
        adv_c_title: "完整的智驾研发工具链",
        adv_c_desc: `- 包括AI部署、软件部署及Profiling工具链、数据采集与SIL HIL，满足智能驾驶开发、调试、优化的需求，为量产落地降低难度、节约时间、缩减成本。`,
        adv_d_title: "丰富的功能及应用场景",
        adv_d_desc: `- 高速NOA+自动泊车 （行泊一体）。 \n- 可实现L2+级基础低速泊车、高速公路自动驾驶，并可拓展至无人售货车、矿山、自动清洁车等自动驾驶场景。`,
      },

      core_property: {
        title: "核心参数",
        camera_interface: {
          name: "算力",
          value: "36Tops+80kDMIPS (ASIL-B)9k+2.7kDMIPS (ASIL-D)",
        },
        display_interface: {
          name: "Camera",
          value:`FVC: 2x 8M@30fps SVC: 2x 3M@30fps 
\n RVC: 1x 3M@30fpsAVM: 4x 2M@30fps`,
        },
        sense_input: {
          name: "感知输入",
          value: "前向雷达 x 1，角雷达 x 4",
        },
        ethernet: {
          name: "USS",
          value: "12 (DSI port * 4) ",
        },
        can: {
          name: "Display",
          value: "3display port",
        },
        gpio: {
          name: "Ethernet",
          value: `Gateway：1*100BASE-T1 
\n 调试预留：1*100BASE-T1+1*1000BASE-T1`,
        },
        arithmetic: {
          name: "CAN",
          value: "CANFD * 6 (1 Channel WakeUp)",
        },
        power_dissipation: {
          name: "IO 接口",
          value: `Input IO * 2 
\n Output IO * 3`,
        },
        ai_driving_level: {
          name: "IMU",
          value: "内置集成",
        },
        function_security: {
          name: "散热",
          value: "风冷",
        },
        info_security: {
          name: "智能驾驶等级",
          value: `封闭道路L2.9 
\n 行泊一体`,
        },
        volume: {
          name: "功能安全",
          value: `Board Level: ASIL – B 
\n Feature Level: UptoASIL-D（with TC397 chip）`,
        },
        tiji: {
          name: "体积",
          value: "≤ 255mm*167mm*70mm",
        },
      },

      nav: {
        summarize: "概述",
        advantage: "优势",
        property: "参数",
        coopearation: "合作咨询",
      },
    },
    takla: {
      name: "RazorDCX Takla",
      description:
        "RazorDCX Takla是畅行智驾推出的面向行泊一体应用场景的域控制器解决方案，采用高通先进的7nm芯片SA8540P，为车辆提供60TOPS 200KDMIPS的算力。Takla提供12路相机最高像素8M Pixels的接入能力，并预留多路CAN/CANFD 接口，提供8路车规级以太网接口，满足自动驾驶对传感器接入的需求。可应用在低速泊车自动驾驶、高速公路自动驾驶、城区自动驾驶，并可拓展至无人售货车、矿山、自动清洁车等封闭园区自动驾驶场景。",

      perform_a: "异构算力",
      perform_b: "丰富的外部接口",
      perform_c: "丰富的应用场景",
      perform_d: "强大的AI工具链",
      perform_e: "灵活的合作方式",

      advantage: {
        adv_a_title: "异构算力",
        adv_a_desc: `RazorDCX Takla提供60TOPS（INT8）的AI算力，并可通过外部AI加速芯片拓展至720TOPS（INT8），200K~400kDMIPS的SOC算力，4kDMIPS的ASIL-D算力，满足自动驾驶的各个场景需求`,
        adv_b_title: "丰富的外部接口",
        adv_b_desc: `RazorDCX Takla提供12路相机最高像素8M Pixel的接入能力，并预留多路CAN/CANFD接口，提供8路车规级以太网接口，满足自动驾驶对传感器接入的需求`,
        adv_c_title: "丰富的应用场景",
        adv_c_desc: `RazorDCX Takla可应用在低速泊车自动驾驶、高速公路自动驾驶、城区自动驾驶，并可拓展至无人售货车、矿山、自动清洁车等封闭园区自动驾驶。`,
        adv_d_title: "强大的AI工具链",
        adv_d_desc: `RazorDCX Takla继承手机、平板等移动平台，支持所有主流的AI框架`,
        adv_e_title: "灵活的合作方式",
        adv_e_desc: `RazorDCX Takla采用算法、软件、硬件相互独立解耦的设计，模块非强绑定强关联，提供给客户灵活多样的合作模式。`,
      },

      core_property: {
        title: "核心参数",
        camera_interface: {
          name: "Camera接口",
          value: "1x 8M@30fps \n 7x 3M@30fps \n 4x 2M@30fps \n 支持前视、周视、环视、OMS、DMS",
        },
        display_interface: {
          name: "Display接口",
          value: "1x MAX96789（两路1080P视频输出）\n 1x MAX96717 （Bypass环视输出）\n 1080p 三路视频输出",
        },
        sense_input: {
          name: "感知输入",
          value: "毫米波雷达x5 \n 超声波 x12 \n 激光雷达x3",
        },
        ethernet: {
          name: "Ethernet",
          value: "车规级：2*1Gb/s +6*100Mb/s \n 调试预留：1Gb/s · Switch:RTL9072",
        },
        can: {
          name: "CAN",
          value: "12通道CAN-FD \n 三路唤醒 (TJA1462. 1145. 1043)",
        },
        gpio: {
          name: "GPIO",
          value: `12+（主要用于超声波）`,
        },
        arithmetic: {
          name: "算力",
          value: "60Tops+200kDMIPS (ASIL-B) \n 4kDMIPS (ASIL-D)",
        },
        power_dissipation: {
          name: "功耗",
          value: "整机<50W",
        },
        ai_driving_level: {
          name: "智能驾驶等级",
          value: "封闭道路L2.9+其他场景L2，行泊一体",
        },
        function_security: {
          name: "功能安全",
          value: "ASIL-D （安全芯片）",
        },
        info_security: {
          name: "信息安全",
          value: "预留国密芯片",
        },
        volume: {
          name: "体积",
          value: "288*188*47",
        },
      },

      nav: {
        summarize: "概述",
        advantage: "优势",
        property: "参数",
        coopearation: "合作咨询",
      },
    },
    pantanal: {
      name: "RazorDCX Pantanal",
      description:
        "RazorDCX Pantanal是畅行智驾新一代量产级智能驾驶域控解决方案，其提供多种阶梯式配置，可满足从入门级到旗舰级车型对智驾域控的不同需求。Pantanal采用高通新一代Snapdragon Ride平台量产级4nm工艺智驾芯片，单芯片算力达50-100TOPS/230KDMIPS，可同时支持基础配置7V5R12USS与高阶配置11V5R12USS接入，并预留多路CAN/CANFD 接口，提供8路车规级以太网接口，满足不同等级智能驾驶对传感器接入的需求。Pantanal可集成安全功能、舒适功能、泊车功能三大模块共计29项ADAS功能，实现记忆泊车、L2.9级高速公路自动驾驶及L3级城区自动驾驶，并可拓展至L4级等封闭园区自动驾驶。",

      perform_a: "强可拓展性",
      perform_b: "高功能安全",
      perform_c: "完整的智驾研发工具链",
      perform_d: "丰富的功能及应用场景",

      advantage: {
        adv_a_title: "强可拓展性",
        adv_a_desc: `算力可拓展：单芯片算力可由100Tops拓展至400Tops，可以支持不同的传感器配置、不同算法的算力需求\n硬件可拓展：支持多种芯片硬件组合解决方案，域控制器在保证软件一致性的前提下，做到接口可拓展、硬件可升级、算力可分配。\n传感器可拓展：可同时支持NOA+泊车7V5R12USS基础解决方案与11V5R12USS NOA+泊车高阶解决方案，其中高阶解决方案可脱离对高精地图的依赖`,

        adv_b_title: "高功能安全",
        adv_b_desc: `Pantanal遵循最严苛的ASIL-D开发标准，采用先进的fail-safe和fail-operational安全架构，满足自动驾驶功能安全需求。高通SOC符合ASIL-B等级，其中的safety island满足ASIL-D等级，域控整体设计可达到ASIL-D要求。`,

        adv_c_title: "完整的智驾研发工具链",
        adv_c_desc: `畅行智驾提供完整的域控工具链、包括AI部署工具链、软件部署及Profiling工具链、数据采集与SIL HIL工具链，满足智能驾驶开发、调试、优化的需求，为量产落地降低难度、节约时间、缩减成本。`,

        adv_d_title: "丰富的功能及应用场景",
        adv_d_desc: `Pantanal可支持安全功能（AEB/FCW/LKA/LDW/ELK/FCTA/FCTB/RCTA /RCTB/DOW/BSW/LCA/RCW/SCW）、舒适功能（ ACC/LCC/TJA/ICA/ IHBC/TSR/SAS/SCF/TLR/HWA/NOP/TJP）、泊车功能（APA/RPA//VPA）三大模块共计29项ADAS功能的集成，可实现L2.9级基础低速泊车、高速公路自动驾驶及L3级城区自动驾驶，并可拓展至无人售货车、矿山、自动清洁车等封闭园区L4级自动驾驶场景。`,
      },

      core_property: {
        title: "核心参数",
        camera_interface: {
          name: "Camera接口",
          value: `2x 8M@30fps \n 5x 3M@30fps \n 4x 2M@30fps \n 支持前视、周视、环视、OMS、DMS`,
        },
        display_interface: {
          name: "Display接口",
          value: "1x MAX96789（两路8540P视频输出）\n 1x MAX96717 （Bypass环视输出）\n 1080p 三路视频输出",
        },
        sense_input: {
          name: "感知输入",
          value: "毫米波雷达x5，超声波 x12，激光雷达x1",
        },
        ethernet: {
          name: "Ethernet",
          value: "车规级：4*1000BASE-T1+ 4*100BASE-T1\n 调试预留： 100BASE-TX\n Switch:88Q5192",
        },
        can: {
          name: "CAN",
          value: "12通道CAN-FD，三路唤醒，\n（TJA1462. 1145. 1043）",
        },
        gpio: {
          name: "GPIO",
          value: `12+`,
        },
        arithmetic: {
          name: "算力",
          value: "100Tops+230kDMIPS (ASIL-B)\n 18k+4kDMIPS (ASIL-D)",
        },
        power_dissipation: {
          name: "功耗",
          value: "整机<60W",
        },
        type_of_cooling: {
          name: "冷却方式",
          value: "液冷",
        },
        ai_driving_level: {
          name: "智能驾驶等级",
          value: "封闭道路L2.9+\\其他场景L2\n 行泊一体",
        },
        function_security: {
          name: "功能安全",
          value: "ASIL-D （安全芯片）",
        },
        info_security: {
          name: "信息安全",
          value: "预留国密芯片",
        },
        volume: {
          name: "体积",
          value: "316*188*48",
        },
      },

      nav: {
        summarize: "概述",
        advantage: "优势",
        property: "参数",
        coopearation: "合作咨询",
      },
    },
    tarkine: {
      name: "RazorDCX Tarkine",
      description:
        "RazorDCX Tarkine是畅行智驾新一代量产级舱驾融合域控解决方案，是行业首款单SOC舱驾融合域控解决方案，该方案采用高通工艺制程为4nm的全新Snapdragon Ride Flex平台车规级芯片，单颗即可同时满足座舱域和智驾域的需求。RazorDCX Tarkine能够实现座舱的3D HMI、舱内视觉、游戏影音娱乐、互联等功能，同时还能兼顾自动泊车、高速NOA等智能驾驶辅助功能要求，此外通过算力拓展从而满足车厂入门级到旗舰级的需求，是目前市场兼具性价比与技术领先性的舱驾融合域控解决方案。",

      perform_a: "丰富的外部接口",
      perform_b: "多样化的功能及应用场景",
      perform_c: "极致的高性价比",
      perform_d: "单芯片算力调度机制和实时通信",
      perform_e: "强大的生态工具链 ",
      perform_f: "高功能安全等级",
      perform_g: "硬件高集成、结构简化",

      advantage: {
        adv_a_title: "丰富的外部接口 ",
        adv_a_desc: `- 对自动驾驶：支持11V5R12U（2路前视8M、4路周视、4路环视、1路后视、5路毫米波雷达及12路超声波雷达）接入，并预留12路CAN/CANFD 接口，提供8路车规级以太网接口 \n- 对座舱：支持多屏互动，支持模拟功放和A2B数字接口，还支持MOST接口可整合网关实现更多ECU控制`,

        adv_b_title: "丰富的功能及应用场景",
        adv_b_desc: `- HPC舱驾融合域控制器可支持IVI、ADAS、Cluster、HUD、AVM、DMS、DVR等功能的集成 \n- 支持L2++高速公路及城区智能驾驶`,

        adv_c_title: "极致的高性价比",
        adv_c_desc: `- 域控成本：舱驾融合解决方案具备性价比优势，其兼顾成本考量的功能安全设计、强大的中间件功能、与高效的工具链保障产品在满足性能及功能需求的同时减轻客户成本压力 \n- 研发成本：中长期来看，会带来研发迭代和升级以及维护的成本节省整车成本：减少整车BOM（线束、周边件）成本；减少供应商管理成本；减少生产、售后等成本`,

        adv_d_title: "单芯片算力调度机制和实时通信",
        adv_d_desc: `- 动态共享算力、共享存储等 \n- 单芯片中间件真正实现垮域实时通信及确定性调度`,

        adv_e_title: "强大的生态工具链",
        adv_e_desc: `- 智能驾驶AI工具链RazorAIKit支持pytorch,onnx,caffe,tensorflow等所有主流AI框架，可与智能座舱共平台，有效提升部署、迭代效率并降低人员投入与反馈成本 \n- RazorWareX工具链符合SOA架构，可以提高系统的灵活性、可扩展性和可重用性，降低系统的耦合度，使得系统更易于维护和演化`,

        adv_f_title: "高功能安全等级",
        adv_f_desc: `- 遵循最严苛的ASIL-D开发标准，采用先进的fail-safe和fail-operational安全架构，满足自动驾驶功能安全需求。ASIL-B的高通SOC不仅内含safety island，外围PMIC也包含监控单元，完整实现了功能安全的监控机制`,

        adv_g_title: "硬件高集成、结构简化",
        adv_g_desc: `- 结构：集成度高，体积小，重量轻 \n- 散热：采用新型腔体式液冷设计，散热效率更高`,
      },

      core_property: {
        title: "核心参数",
        camera_interface: {
          name: "Camera接口",
          value: `2x 8M@30fps \n 5x 3M@30fps \n 4x 2M@30fps \n 支持前视、周视、环视、OMS、DMS`,
        },
        display_interface: {
          name: "Display接口",
          value: "1x MAX96789（两路1080P视频输出）\n 1x MAX96717 （Bypass环视输出）\n 1080p 三路视频输出",
        },
        sense_input: {
          name: "感知输入",
          value: "毫米波雷达x5，超声波 x12，激光雷达x1",
        },
        ethernet: {
          name: "Ethernet",
          value: "车规级：4*1000BASE-T1+ 4*100BASE-T1\n 调试预留： 100BASE-TX\n Switch:88Q5192",
        },
        can: {
          name: "CAN",
          value: "12通道CAN-FD 三路唤醒 \n (TJA1462. 1145. 1043)",
        },
        gpio: {
          name: "GPIO",
          value: `12+`,
        },
        arithmetic: {
          name: "算力",
          value: "72Tops+230kDMIPS (ASIL-B)",
        },
        power_dissipation: {
          name: "功耗",
          value: "整机<60W",
        },
        type_of_cooling: {
          name: "冷却方式",
          value: "液冷",
        },
        ai_driving_level: {
          name: "智能驾驶等级",
          value: "封闭道路L2.9+\\其他场景L2\n 行泊一体",
        },
        function_security: {
          name: "功能安全",
          value: "ASIL-D （安全芯片）",
        },
        info_security: {
          name: "信息安全",
          value: "预留国密芯片",
        },
        volume: {
          name: "体积",
          value: "343*238*39.5",
        },
      },

      nav: {
        summarize: "概述",
        advantage: "优势",
        property: "参数",
        coopearation: "合作咨询",
      },
    },

    rwx1: {
      name: "RazorWareX 1.0",
      description:
        "RazorWareX为智能驾驶应用算法提供多种服务，在域控器各芯片间和车端各系统之间配置了高实时性、高宽带的通信骨干网，充分调配硬件算力，共享传感器数据，提供给AI算法运行环境，为智能驾驶提供高可靠性的开放软件平台。",

      perform_a: "开放平台，支持算法生态并行开发",
      perform_b: "健全的平台服务软件",
      perform_c: "跨芯片、跨域的异构RTE接口",
      perform_d: "时间触发通信骨干网",
      perform_e: "高功能安全",
      perform_f: "用户友好的配置工具链，代码自动生成",

      advantage: {
        adv_a_title: "开放平台，支持算法生态并行开发",
        adv_a_desc: `通过中间件模块灵活可配置、标准的通信接口、可视化开发工具，使能开发流程的合理化，简化开发智驾系统的复杂度`,
        adv_b_title: "健全的平台服务软件",
        adv_b_desc: `通过运算结果一致性、高可靠功能安全平台，使能客户研发易验证、高行驶安全的智驾系统。同时在系统稳定、资源合理分配和运行平顺方面为智驾域控赋能`,
        adv_c_title: "跨芯片、跨域的异构RTE接口",
        adv_c_desc: `多种时间域的架构设计，支持域控制器内部主机间及内外部控制器间工作在相同时间域。  
多核异构的架构设计，满足域控制器内部主机间的大数据交互场景，支持功能算法灵活部署。 
        `,
        adv_d_title: "时间触发通信骨干网",
        adv_d_desc: `时间触发任务的架构设计，兼容事件触发任务和数据触发任务的客户特殊场景`,
        adv_e_title: "高功能安全",
        adv_e_desc: `功能安全兼顾的架构设计，支持安全等级目标ASIL_D`,
        adv_f_title: `用户友好的配置工具链，代码自动生成：`,
        adv_f_desc: `工具链全方位支撑代码生成的架构设计，满足需求定义软件的复杂系统开发体系，支持软件快速迭代`,
      },

      nav: {
        summarize: "概述",
        advantage: "优势",
        coopearation: "合作咨询",
      },
    },

    aikit: {
      name: "RazorAIKit",
      description:
        "RazorAIKit是畅行智驾为智能驾驶AI算法部署提供的工具链，基于高通深度学习推理加速框架QNN开发，将模型的开发训练与部署分离，专注于解决模型部署阶段的难点与问题。RazorAIKit提供了统一的图形化部署工作流，让模型部署一键完成。引入版本管理，满足单项目多模型的部署需求；同时RazorAIKit还集成了模型可视化，部署结果多平台验证，多模型性能测试对比，算子运行统计等众多实用工具，让调试与优化变得方便快捷。",

      perform_a: "统一的部署工作流",
      perform_b: "项目级版本管理",
      perform_c: "模型可视化",
      perform_d: "多平台验证",
      perform_e: "算子运行分析",

      advantage: {
        adv_a_title: "统一的部署工作流",
        adv_a_desc: `化繁为简，整合部署过程中全部工作流，提供自动量化与验证，实现一键部署`,
        adv_b_title: "项目级版本管理",
        adv_b_desc: `可根据项目进行批量操作，同时配置部署不同版本、不同任务的算法模型，并提供项目内模型验证与性能对比`,
        adv_c_title: "模型可视化",
        adv_c_desc: `提供模型可视化功能，方便对于模型配置的修改与调整`,
        adv_d_title: "多平台验证",
        adv_d_desc: `提供x86运行模拟器与端侧平台结果验证；同时提供多端，多语言运行接口`,
        adv_e_title: "算子运行分析",
        adv_e_desc: `提供算子级别运行性能可视化分析，为算子优化与模型部署配置提供清晰的对比数据`,
      },

      response_time: "反馈时间",
      human_investion: "人员投入",
      learning_cost: "学习成本",
      iteration_efficient: "迭代效率",
      deploy_efficient: "部署效率",
      adv_tip: "使用RazorAIKit进行六路视觉障碍物与车道线感知算法部署，工作量由10人日降为3人日",

      nav: {
        summarize: "概述",
        advantage: "优势",
        coopearation: "合作咨询",
      },
    },

    contact_tip: "联系我们获得更多产品信息",
    contact_btn: "合作咨询",
  },

  coopearation: {
    contact_us: "联系我们",
    business_coperation: "业务合作",
    resume_delivery: "简历投递",
    phone_number: "电话号码",

    business_consulting: "合作咨询",
    company: "公司名称",
    name: "您的姓名",
    position: "您的职位",
    email: "电子邮箱",
    mobile: "电话号码",
    context: "咨询内容",
    submit: "确认提交",
  },

  about_us: {
    banner_title: "关于我们",
    banner_tip: "ABOUT US",

    company_profile: "公司简介",
    mission_vision: "使命愿景",
    development_course: "发展历程",
    development_center: "研发中心",
    qualification: "资质认证",
    contact_us: "联系我们",

    introduction: `苏州畅行智驾汽车科技有限公司（以下简称：畅行智驾）是由中科创达投资成立的专注于开发智能驾驶域控制器以及大算力中央计算机（HPC）的软硬件平台企业。
公司面向全球智能汽车客户提供创新、安全且高度解耦的域控平台和低延时、高可靠性的核心自研中间件软件，并配备完整的工具链。
公司依托中科创达在座舱软件领域的技术领先优势，整合操作系统、芯片、感知、智能制造及算法等生态战略合作伙伴，共同打造可落地的智能驾驶域控及跨域融合产品，
可为客户提供多类共创和全栈式的灵活商业服务模式。`,
    vision: "愿景",
    vision_tip: "让驾驶更智慧，让出行更美好",
    mission: "使命",
    mission_tip: "打造共赢智能驾驶生态圈",
    sense_value: "价值观",
    sense_value_tip: "成就客户，引领创新，以人为本，拥抱变化",

    business_cooperation: "业务合作",
    mobile: "电话",

    multip_dev_center: "多地研发中心",

    department_a: "上海办公室",
    department_a_add: "上海市徐汇区田林路487号宝石园26号楼6楼",

    department_b: "苏州办公室",
    department_b_add: "苏州工业园区金鸡湖大道1355号国际科技园四期2楼",
  },

  join_us: {
    cx_life: {
      label: "工作环境",
      openness_office: "开放式办公区域",
      inhouse_rd: "研发实验室",
      lounge: "休闲茶歇区",
      reading_corner: "共享图书角",
    },
    cx_culture: {
      label: "企业文化",
      all_heads_meeting: "All Hands Meeting",
      new_staff_training: "新员工培训",
      enginner_forum: "工程师论坛",
      fresh_graduate: "应届生培养项目",
    },
    cx_care: {
      label: "员工关怀",
      physical_examination: "年度体检",
      organization_activty: "社团活动",
      holiday_welfare: "年节福利",
      welfare_benifit: "福利津贴",
      flexable_office_hours: "弹性办公时间",
    },
  },

  recuritment: {
    count_txt: "个在招职位",
    rec_search_placehold: "请搜索职位名称",
    rec_search_address: "工作地点",
    rec_search_kind: "职位类型",
    reset_condi: "清空筛选条件",

    table: {
      position_name: "职位名称",
      position_type: "职位类型",
      department: "所属部门",
      need_number: "招聘人数",
      address: "工作地点",
      create_time: "发布时间",
    },

    pagination: {
      jump_to: "跳至",
      page: "页",
      certain: "确认",
    },

    join_in: "立即投递",
    position_responsibility: "工作职责：",
    position_require: "任职要求：",

    delivery: "投递简历",
    form: {
      name: "姓名：",
      gender: "性别：",
      mobile: "电话：",
      email: "邮箱：",
      education: "学历：",
      graduated: "院校：",
      profession: "专业：",
      prifle: "简历：",
      submit: "确认提交",
    },
  },

  search: {
    input_placehold: "搜索",
    text: "搜索",
    result: "结果为",
  },
};
