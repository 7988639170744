<template>
  <div class="containner" :class="is_mobile ? 'm-containner' : 'pc-containner'">
    <div class="pro-nav-bar" :class="nav_is_fixed ? 'pro-nav-bar-fixed' : ''">
      <!-- <img class="pro-logo-img" :src="pro_log" /> -->
      <div class="pro-nav-wrap">
        <div class="pro-icon">
          <div class="pro-it-btn" :class="nav_act == 1 ? 'pro-it-btn-act' : ''" @click="scrollToSummarize">
            <!-- {{ $t("pro.tongass.nav.summarize") }} -->概述
          </div>
          <div class="pro-it-btn" :class="nav_act == 2 ? 'pro-it-btn-act' : ''" @click="scrollToOpenCockpit">
            <!-- {{ $t("pro.tongass.nav.advantage") }} -->Rubik Auto Pi-OpenCockpit Dev Kit
          </div>
          <div class="pro-it-btn" :class="nav_act == 3 ? 'pro-it-btn-act' : ''" @click="scrollToMozi">
            <!-- {{ $t("pro.tongass.nav.property") }} -->Kanzi Mozi
          </div>
        </div>
        <div class="pro-cooperation" @click="showCooparationForm">
          {{ $t("pro.tongass.nav.coopearation") }}
        </div>
      </div>
    </div>
    <div class="banner" id="summarize">
      <img class="banner-bg" v-if="$t('language.shot_name') == 'cn'" :src="is_mobile ? banner_m : banner" />
      <img class="banner-bg" v-if="$t('language.shot_name') == 'en'" :src="is_mobile ? banner_m_en : banner_en" />
      <div class="pro-series-content">
        <div class="pro-series-name"><!--{{ $t("pro.tongass.name") }}-->Rubik Auto Pi 开源项目</div>
        <div class="pro-series-desc"><!--{{ $t("pro.tongass.description") }}-->
          <p>Rubik Auto Pi 开源项目旨在为汽车电子爱好者、开发者以及相关企业提供一个高度灵活且功能丰富的汽车操作系统开发平台。</p>
          <p>它基于先进的电子架构，融合了硬件模块化设计与开源软件生态，助力用户快速迭代和定制化开发各类汽车软件平台应用，从智能仪表盘、中控娱乐系统到智能驾驶等，满足不同场景下的汽车智能化功能需求。</p>
        </div>
      </div>
    </div>
    <div class="performance-wrap">
      <img class="perform-bg" :src="is_mobile ? perform_bg_m : perform_bg" />
      <div class="perform-box">
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[0].icon" />
          <div class="perform-tit"><!--{{ this.$t("pro.tongass.perform_a") }}-->高灵活性</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[1].icon" />
          <div class="perform-tit"><!--{{ this.$t("pro.tongass.perform_b") }}-->丰富功能</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[2].icon" />
          <div class="perform-tit"><!--{{ this.$t("pro.tongass.perform_c") }}-->软硬件融合</div>
        </div>
        <!-- <div class="perform-list">
          <img class="perform-icon" :src="perform_list[3].icon" />
          <div class="perform-tit">{{ this.$t("pro.tongass.perform_d") }}</div>
        </div>-->
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[3].icon" />
          <div class="perform-tit"><!--{{ this.$t("pro.tongass.perform_e") }}-->多场景适用</div>
        </div>
      </div>
    </div>

    <!-- 自定义内容 by zhangwei 20241218-->

    <div class="banner" id="opencockpit">
      <img class="banner-bg" v-if="$t('language.shot_name') == 'cn'" :src="is_mobile ? banner1_m : banner1" />
      <img class="banner-bg" v-if="$t('language.shot_name') == 'en'" :src="is_mobile ? banner_m_en : banner_en" />
      <div class="pro-series-content">
        <div class="pro-series-name"><!--{{ $t("pro.tongass.name") }}-->Rubik Auto Pi-OpenCockpit Dev Kit</div>
        <div class="pro-series-desc"><!--{{ $t("pro.tongass.description") }}-->
          <p>Rubik Auto Pi-OpenCockpit Dev Kit 是一款具有强大AI能力的大算力物联网消规级平台，用于智能汽车座舱软件设计与开发，AI算力最高可达96TOPS，可在车机座舱之上运行Transformer大模型、基于GPT的本地语音大模型等，同时可实现端侧数据管理。该平台即将于2025年4月份的上海车展上正式发布，感谢您的关注与期待。
          </p>
        </div>
      </div>
    </div>


    <div class="section1">
      <img src="@/assets/devkit-section1-bg.jpg" alt="" style="width: 100%;height: 100%;">
    </div>


    <div class="banner" id="mozi">
      <img class="banner-bg" v-if="$t('language.shot_name') == 'cn'" :src="is_mobile ? banner2_m : banner2" />
      <img class="banner-bg" v-if="$t('language.shot_name') == 'en'" :src="is_mobile ? banner_m_en : banner_en" />
      <div class="pro-series-content">
        <div class="pro-series-name"><!--{{ $t("pro.tongass.name") }}-->Kanzi Mozi</div>
        <div class="pro-series-desc"><!--{{ $t("pro.tongass.description") }}-->
          <p>该产品专为HMI设计初学者和个人用户打造，能够支持Kanzi One本地编辑所需的全部功能模块，并允许用户长期免费试用。</p>
          <p>
            Kanzi Mozi提供直观、易用的用户界面设计模板和工具不仅降低了初学者的开发设计门槛，也为更多开发者提供了免费的设计实践机会。它在帮助使用者快速熟悉和掌握汽车HMI开发的相关技术和知识的同时，也可帮助专业人士进行设计和概念测试，探索新产品或模型的可能性，真正实现了零成本HMI设计创新的构想。
          </p>
        </div>
      </div>
    </div>


    <div class="section1">
      <img v-if="$t('language.shot_name') == 'cn'" :src="is_mobile ? banner3_m : banner3"  style="width: 100%;height: 100%;"/>
      <!-- <img src="@/assets/dev-kit-guanzhu.jpg" alt=""> -->
    </div>



    <div class="section2">
      <div class="section2-title">Kanzi教学视频</div>
      <div class="section2-desc"><p>从零开始的Kanzi教学，通过实际案例讲解并提供相关工程，加速您的学习过程</p><p>
        使您能够更好地设计和开发汽车HMI</p></div>
      <div class="section2-item-wrap">
        <div class="section2-item">
          <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=263745964&bvid=BV17e411w7T8&cid=921170357&p=1&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
                
        </div>
        <div class="section2-item">
          <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=946218497&bvid=BV1AW4y1u7q5&cid=921177759&p=1&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
        </div>
        <div class="section2-item">
          <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=733627160&bvid=BV15D4y1a7kf&cid=921186284&p=1&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
        </div>
      </div>
      <div class="section2-item-wrap">
        <div class="section2-item-1">
          <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=733702125&bvid=BV1qD4y1a77P&cid=921197399&p=1&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
          
        </div>
        <div class="section2-item-1">
          <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=776188108&bvid=BV1814y1T7xe&cid=921212112&p=1&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
          
        </div>
        <div class="section2-item-1">
          <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=691040292&bvid=BV1n24y1Q7DH&cid=917563705&p=1&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
          
        </div>
        
      </div>
      <div class="section2-item-wrap">
        <a href="https://space.bilibili.com/301867101" target="_blank">
          <h2>更多视频教程请点此查看 ></h2>
        </a>
        
      </div>
    </div>


    <cx-contact @showCoopationForm="showCooparationForm"></cx-contact>
    <cx-cover-layout v-if="show_cooparation_form">
      <cx-cooperation source="Takla" @hideCooparationForm="hideCooparationForm"></cx-cooperation>
    </cx-cover-layout>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import Contact from "../../components/Contact.vue";
// import ProAdvantage from "../../components/ProAdvantage.vue";
import Cooperation from "../../components/Cooperation.vue";
import CoverLayout from "../../components/CoverLayout.vue";

gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "RazorDCXTakla",
  components: {
    "cx-contact": Contact,
    // "cx-pro-advantage": ProAdvantage,
    "cx-cooperation": Cooperation,
    "cx-cover-layout": CoverLayout,
  },
  props: {},
  data() {
    return {
      nav_act: 1,
      show_cooparation_form: false,

      pro_log: require("@/assets/pro-tongass-logo.png"),
      pro_data_bg: require("@/assets/takla-data-bg.png"),

      banner: require("@/assets/dev-kit-banner.jpg"),
      banner1: require("@/assets/OpenCockpit-Dev-banner.jpg"),
      banner1_m: require("@/assets/OpenCockpit-Dev-banner-m.jpg"),
      banner2: require("@/assets/kanzi-mozi-banner.jpg"),
      banner2_m: require("@/assets/kanzi-mozi-banner-m.jpg"),
      banner3: require("@/assets/dev-kit-guanzhu.jpg"),
      banner3_m: require("@/assets/dev-kit-guanzhu-m.jpg"),
      section2_left_bg: require("@/assets/section2-left-bg.jpg"),
      section2_right_bg: require("@/assets/section2-right-bg.jpg"),
      banner_en: require("@/assets/dev-kit-banner.jpg"),
      banner_m: require("@/assets/dev-kit-banner-m.jpg"),
      banner_m_en: require("@/assets/tongass-banner-m.png"),
      series_case: require("@/assets/series-razordcx-takla.png"),
      perform_bg: require("@/assets/i-bg-a.png"),
      perform_bg_m: require("@/assets/series-bg.png"),

      advantage_list: [
        {
          bg: require("@/assets/tongass-det-a.png"),
          bg_m: require("@/assets/tongass-det-a-m.png"),
        },
        {
          bg: require("@/assets/tongass-det-b.png"),
          bg_m: require("@/assets/tongass-det-b-m.png"),
        },
        {
          bg: require("@/assets/tongass-det-c.png"),
          bg_m: require("@/assets/tongass-det-c-m.png"),
        },
        // {
        //   bg: require("@/assets/takla-det-d.png"),
        //   bg_m: require("@/assets/takla-det-d-m.png"),
        // },
        // {
        //   bg: require("@/assets/takla-det-e.png"),
        //   bg_m: require("@/assets/takla-det-e-m.png"),
        // },
      ],

      perform_list: [
        {
          icon: require("@/assets/devkit-icon-a.png"),
        },
        {
          icon: require("@/assets/devkit-icon-b.png"),
        },
        {
          icon: require("@/assets/devkit-icon-c.png"),
        },
        {
          icon: require("@/assets/devkit-icon-d.png"),
        },

      ],

      nav_is_fixed: false,
      tl: null,
      tl3: null,
    };
  },
  methods: {
    hideCooparationForm() {
      this.show_cooparation_form = false;
    },
    showCooparationForm() {
      this.show_cooparation_form = true;
    },
    scrollToSummarize() {
      let off_set = 30;
      if (this.is_mobile) {
        off_set = 50;
      }
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#summarize", offsetY: off_set } });
      this.nav_act = 1;
    },
    scrollToOpenCockpit() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#opencockpit", offsetY: 100 } });
      this.nav_act = 2;
    },
    scrollToMozi() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#mozi", offsetY: 65 } });
      this.nav_act = 3;
    },
  },
  destroyed() {
    this.tl.kill();
    this.tl3.kill();
    this.tl = null;
    this.tl3 = null;
  },
  mounted() {
    let that = this;

    this.tl = gsap.timeline({});
    this.tl
      .addLabel("start")
      .from(".banner-bg", {
        opacity: 0,
        x: 100,
      })
      .from(".pro-series-name", {
        opacity: 0,
        y: 50,
      })
      .from(".pro-series-desc", {
        opacity: 0,
        y: 50,
      })
      .from(".pro-series-btn", {
        opacity: 0,
        y: 50,
      })
      .from(
        ".perform-list",
        {
          opacity: 0,
          duration: 0.5,
          y: 50,
        },
        "<"
      )
      .from(".perform-tit", {
        opacity: 0,
        delay: -0.3,
        y: 50,
      });

    let tl3_start = 100;

    this.tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".pro-advantage-box",
        pin: true,
        start: "-" + tl3_start + " top",
        end: "+=1000",
        scrub: 0,
      },
    });

    let trans_scale = this.rate * 650;
    this.tl3
      .addLabel("begin")
      .to(".ani-tl-0", { opacity: 1, y: 0, scale: 0.95 })
      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 1 }, "<")

      .to(".ani-tl-0", { opacity: 1, y: 0, scale: 0.85 })
      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1, scale: 0.95 }, "<")
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 2 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 2 }, "<")

      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1, scale: 0.85 })
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2, scale: 0.95 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 3 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 3 }, "<")

      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2, scale: 0.85 })
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 3, scale: 0.95 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 4 }, "<");

    window.addEventListener("scroll", function () {
      const scroll_top = that.getScrollTop();
      if (scroll_top < that.rate * 100) {
        that.nav_is_fixed = false;
      } else {
        that.nav_is_fixed = true;
      }
      if (!that.is_mobile) {
        if (scroll_top < that.rate * 1140) {
          that.nav_act = 1;
        } else if (scroll_top >= that.rate * 1140 && scroll_top <= that.rate * 3350) {
          that.nav_act = 2;
        } else if (scroll_top >= that.rate * 3350) {
          that.nav_act = 3;
        }
      } else {
        if (scroll_top < that.rate * 1940) {
          that.nav_act = 1;
        } else if (scroll_top >= that.rate * 1940 && scroll_top <= that.rate * 4620) {
          that.nav_act = 2;
        } else if (scroll_top >= that.rate * 4620) {
          that.nav_act = 3;
        }
      }
    });
  },
};
</script>
<style lang="less" scoped>
.pro-advantage-box {
  margin: 120px 0px 0px;
  height: 700px;
}

.pro-nav-bar {
  position: relative;
  top: 0px;
  height: 86px;
  width: 100%;
  z-index: 111;
  background-color: rgba(36, 36, 36, 1);
}

.pro-nav-bar-fixed {
  position: fixed;
  top: 0px;
}

.pro-ad-list {
  position: relative;
  width: 1300px;
  height: 650px;
  margin: 0 auto;
}

.ani-tl-0 {
  z-index: 101;
}

.ani-tl-1 {
  z-index: 102;
}

.ani-tl-2 {
  z-index: 103;
}

.ani-tl-3 {
  z-index: 104;
}

.ani-tl-4 {
  z-index: 105;
}

.ani-tl-5 {
  z-index: 106;
}

.pro-logo-img {
  position: absolute;
  height: 84px;
  left: 223px;
}

.pro-nav-wrap {
  position: absolute;
  right: 230px;
  top: 26px;
}

.pro-it-btn {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  display: inline-block;
  color: #fff;
  margin-left: 90px;
  border-bottom: 1px solid rgba(36, 36, 36, 1);
  transition: 300ms linear all;
  cursor: pointer;
  // box-shadow: 0px 2px 4px 0px rgba(0, 183, 255, 1);
}

// .pro-it-btn:hover {
//   border-bottom: 1px solid rgba(51, 146, 255, 1);
// }
.pro-it-btn-act {
  border-bottom: 1px solid rgba(51, 146, 255, 1);
}

.pro-cooperation {
  display: inline-block;
  padding: 0px 10px;
  height: 35px;
  line-height: 35px;
  opacity: 1;
  font-size: 16px;
  margin-left: 90px;
  font-weight: 400;
  height: 35px;
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
  border-radius: 3.19px;
  background: rgba(144, 218, 252, 1);
}

.pro-icon {
  display: inline-block;
}

.containner {
  padding-top: 86px;
  position: relative;
  overflow: hidden;
}

.banner {
  position: relative;
  height: 587px;
}

.banner-bg {
  position: absolute;
  width: 1920px;
  height: 587px;
  z-index: 0;
  left: 0px;
  top: 0px;
}

.pro-series-content {
  position: absolute;
  color: #fff;
  z-index: 11;
  top: 103px;
  left: 230px;
}

.pro-series-name {
  font-size: 44px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 29px;
}

.pro-series-desc {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  width: 700px;
  text-align: left;
}

.performance-wrap {
  position: relative;
  height: 530px;
  overflow: hidden;
}

.perform-bg {
  position: absolute;
  z-index: 0;
  left: 0px;
  bottom: 0px;
  width: 1920px;
  height: 709px;
}

.perform-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 11;
}

.perform-list {
  margin-top: 152px;
  padding: 0px 50px;
}

.perform-icon {
  width: 101px;
  height: 101px;
}

.perform-tit {
  text-align: center;
  width: 184px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  margin-top: 21px;
}

.takal-data-wrap {
  position: relative;
  width: 1920px;
  height: 1194px;
}

.takal-data-bg {
  position: absolute;
  width: 1920px;
  height: 1194px;
  z-index: 0;
  left: 0px;
  bottom: 0px;

  overflow: hidden;
}

.takal-data-core {
  position: relative;
  width: 95%;
  max-width: 1000px;
  top: 120px;
  margin: 0 auto;
  z-index: 11;
}

.takal-core-title {
  font-size: 44px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.takal-core-list {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  padding: 15px 0px;
}

.takal-core-key {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-left: 106px;
  vertical-align: middle;
  width: 220px;
  float: left;
  text-align: left;
}

.takal-core-key-2 {
  margin-top: 20px;
}

.takal-core-key-3 {
  // margin-top: 30px;
}



.takal-core-value {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  white-space: pre-wrap;
  float: left;
  text-align: left;
}

.m-containner {
  padding-top: 180px;

  .banner {
    position: relative;
    height: auto;
    overflow: hidden;
    height: 1118px;

    .banner-bg {
      width: 750px;
      height: 1118px;
    }

    .pro-series-content {
      width: 100%;
      text-align: center;
      left: 0px;

      .pro-series-name {
        width: 620px;
        font-size: 50px;
        margin: 0 auto;
        margin-bottom: 30px;
      }

      .pro-series-desc {
        width: 620px;
        font-size: 25px;
        margin: 0 auto;
      }
    }
  }

  .performance-wrap {
    width: 750px;
    overflow: hidden;
    height: 634px;
    padding-top: 40px;

    .perform-bg {
      width: 750px;
      height: 674px;
    }

    .perform-box {
      padding-top: 40px;

      .perform-list {
        padding: 10px;
        margin-top: 60px;
      }
    }
  }

  .takal-core-value {
    width: 500px;
  }

  .takal-core-key {
    width: 160px;
    margin-left: 40px;
  }

  .pro-nav-bar {
    height: 180px;

    .pro-logo-img {
      left: 35px;
    }

    .pro-cooperation {
      width: 130px;
      height: 45px;
      line-height: 45px;
      font-size: 24px;
      text-align: center;
    }

    .pro-nav-wrap {
      width: 100%;
      right: 23px;
      text-align: right;

      .pro-icon {
        position: absolute;
        width: 700px;
        left: 0px;
        text-align: left;
        top: 65px;
        font-size: 24px;

        .pro-it-btn {
          font-size: 27px;
          padding: 10px 0px;
        }
      }
    }
  }

  .pro-advantage-box {
    height: 750px;

    .pro-ad-list {
      width: 750px;
    }
  }

  .takal-data-wrap {
    width: 750px;
  }

  .takal-data-core {
    top: 70px;
  }
}

.section2 {
        // background: #F7F7F7;
        width: 80%;
        margin: 0 auto;
        padding: 90px 0px;
      }
      .section2 iframe{width: 100%;height: 250px;}
      .section2-title {
        font-size: 2.75rem;      
        color: #fff;
        text-align: center;
        padding: 20px 0;
      }
      .section2-desc {
        font-size: 1.125rem;
        line-height: 2rem;
        color: #fff;
        text-align: center;
        padding: 10px 0;
      }
      .section2-item-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px;
      }
      .section2-item {
        width: 31.7%;
        padding: 10px;
        box-sizing: border-box;
        background-color: #393939;
        border-radius: 5px;
        margin: 10px;

      }
      .section2-item-1 {
        width: 31.7%;
        padding: 10px;
        box-sizing: border-box;
        background-color: #393939;
        border-radius: 5px;
        margin: 10px;
      }
      .section2-item-wrap a, h2{
        width: 100%;
        margin: 0 auto;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: #fff;
        text-decoration: underline;
        text-align: center !important;     
      }   




      @media screen and (max-width: 768px) {
        .section2 {
        // background: #F7F7F7;
        width: 100%;
        margin: 0 auto;
        padding: 60px 0px;
      }
      .section2 iframe{width: 100%;height: 400px;}
      .section2-title {
        font-size: 2.75rem;      
        color: #fff;
        text-align: center;
        padding: 20px 0;
      }
      .section2-desc {
        font-size: 1.5rem;
        line-height: 2rem;
        color: #fff;
        text-align: center;
        padding: 10px 20px;
      }
      .section2-item-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 20px;
      }
      .section2-item {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        background-color: #393939;
        border-radius: 5px;
        margin: 10px;

      }
      .section2-item-1 {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        background-color: #393939;
        border-radius: 5px;
        margin: 10px;
      }
      .section2-item-wrap a, h2{
        width: 100%;
        margin: 0 auto;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: #fff;
        text-decoration: underline;
        text-align: center !important;     
      }   
      }


</style>
