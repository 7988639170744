/* eslint-disable no-irregular-whitespace */
module.exports = {
  language: {
    name: '中',
    shot_name:'en'
  },
  title:'ThunderX | Let Intelligent Driving Light Your Way',
  topbar: {
    home: 'Home',
    productions: 'Products',
    productions_razor_dcx:'RazorDCX',
    productions_razor_ware_x:'RazorWareX',
    productions_razor_aikit: 'Others',
    productions_dev_kit: "Development Kit",
    news: 'News',
    about_us: 'About Us',
    join_us: 'Join Us'
  },

  footer:{
    logo_labe:'Let Intelligent Driving Light Your Way',

    company_production:'PRODUCTS',
    razor_dcx:'RazorDCX',
    razor_ware_x:'RazorWareX',

    about_us:'ABOUT US',
    company_introduction:'Introduction',
    company_vision:'Mission & Vision',
    company_process:'History',
    contact_us:'Contact us',
    
    
    
    join_us:'JOIN US',
    social_recruitment:'Social Recruitment',
    campus_recruitement:'Campus Recruitment',
    internal_recruitement:'Intern Recruitment',

    social_media:'SOCIAL MEDIA',

    icp_number: "Copyright © 2022-2025 畅行智驾 苏ICP备2025158457号",
    net_icp_number:"沪公网安备 31011402003368号"
  },

  home:{
    advantage_title:'Let Intelligent Driving Light Your Way',
    advantage_txt:`Deeply explore and deploy intelligent driving systems, focusing on the commercialization of 
intelligent driving domain controller and HPC platforms`,
    adv_science_tech:'Technology',
    adv_smart:'Intelligence',
    adv_open:'Open',

    join_us:'Join Us',
    vendor:'The international precedence vendor for AI driving calulation platform',

    ecology_ablity:'Ecological Capacity',
    ecology_ablity_desc:'Deep cooperation with mainstream chip manufacturers and integration of upstream and downstream partners in the industrial chain with the help of the cutting-edge software capabilities of ThunderSoft, to contribute to the next generation of travel modes',
    precise_local:'Precise Positioning',
    precise_local_desc:'Taking the medium HashRate intelligent driving domain controller products as the starting point, we simultaneously build a multi-platform strategy to create cost-effective intelligent driving products, and strive to become the leading intelligent driving Tier1 in China',
    precede_tech:'Leading Technology',
    precede_tech_desc:'With vehicle-level domain controller hardware design capabilities, mature middleware technology and its tool chain, and complete perception algorithm deployment, we can provide an industry-leading platform for intelligent driving and cockpit',
    bussiness_model:'Business Model',
    bussiness_model_desc:'Pioneered the "domain controller +" cooperation model, we customize the delivery model according to customer needs to ensure customer technical dominance and independence',


    pro_razor_dcx_tit:'RazorDCX',
    pro_razor_warex_tit:'RazorWareX',
    pro_razor_dcx_desc:'RazorDCX is a centralized intelligent driving decision controller that integrates environmental perception, information fusion, decision planning, and driving execution functions. It is a vehicle-level high-performance real-time computing platform for the development and implementation of intelligent driving strategies.',
    pro_razor_warex_desc:"RazorWareX provides multiple services for intelligent driving application algorithms, and configures a high-real-time and high-bandwidth communication backbone network between the chips of the domain controller and the systems of the vehicle end, which fully deploys hardware computing power, shares sensor data, to provide AI algorithm running environment and a highly reliable open software platform for intelligent driving.",
    pro_desc_btn:'DETAIL',
  },

  news:{
    bg_title:'HEADLINES NEWS',
    bg_tip:'HEADLINES  NEWS'
  },

  pro:{
    razordcx:{
      name:'RazorDCX',
      description:'RazorDCX is a centralized intelligent driving decision controller that integrates environmental perception, information fusion, decision planning, and driving execution functions. It is a vehicle-level high-performance real-time computing platform for the development and implementation of intelligent driving strategies.',

      perform_a:'High HashRate: AI HashRate 50TOPS-2000TOPS CPU HashRate 200-500kDMIPS',
      perform_b:'ASIL-D high functional security level',
      perform_c:'Supporting 12-channel camera, up to 8M-Pixels',
      perform_d:'Supporting all-time travel and parking, up to L2.9+',
      perform_e:'NOA requiring no high-precision map and positioning',

      pro_name:'RazorDCX Takla',
      pro_desc:'Domain controller for intelligent driving can be applied to passenger vehicles, commercial vehicle driving assistance, closed park automatic driving and other application scenarios',

      pro_btn:'More Detail',
    },

    razorwarex:{
      name:'RazorWareX',
      description:'RazorWareX provides plenty of services for autonomous driving. A communication backbone network with real-time performance and high bandwith is configured between MCU and SoC of the domain controller. It fully deploys hardware computing resources, shares sensor data, provides AI algorithm run time environment and provides an open software platform with high reliability for autonomous driving.',

      perform_a:'High real-time',
      perform_b:'High certainty',
      perform_c:'High functional security',
      perform_d:'End to end delay guarantee',
      perform_e:'Lowest sensitive data delay',

      pro_name:'RazorWareX 1.0',
      pro_desc:'High functional, secure, high real-time, deterministic middleware platform dedicated to intelligent driving scenarios',

      pro_btn:'More Detail',
    },
    // 张伟20240516
    tongass: {
      name: 'RazorDCX Tongass',
      description: "RazorDCX Tongass is the new generation of mass-produced level cockpit-parking fusion domain control solution of ThunderX. The solution adopts the latest 4nm automotive chipset Snapdragon 8255 with up to 230K DMIPs CPU power, and up to 46 TOPs of AI computing. Snapdragon 8255 provides more smooth, realistic graphic effects, more intelligent interactive experiences, and more excellent image processing capabilities. Supplemented by the in-depth understanding in IVI and ADAS by the team of Thundersoft/ThunderX, it presents a Cockpit-parking fusion domain control solution with both high performance and competive cost to OEM.",

      perform_a: 'Strong expandability',
      perform_b: 'High functional safety',
      perform_c: 'Breakthrough fusion experience',

      advantage: {
        adv_a_title: 'Strong Expandability',
        adv_a_desc: `- Based on the latest process chip ecosystem of Qualcomm's fourth-generation chip platform, the combination and switching ofSmart Driving Domain, Cockpit Domain and Fusion Domain can be realized through design adjustments, which can derive morepossibilities for domain control solutions.`,
        adv_b_title: 'High functional safety',
        adv_b_desc: `- Adhere to the strictest ASIL-D development standards and adopt advanced fail safe and fail operational security architectures.`,
        adv_c_title: 'Breakthrough fusion experience',
        adv_c_desc: `- Combined with AquaDrive Pre 1.0 8255 Cockpit, in terms of Cockpit functions, it provides users with a better VGUI GenerativeNatural Interactive Experience, full-scene intelligent visual perception, and scenario-based immersive 3D rendering effects. Inaddition, Tongass can integrate APA, RPA and relevant parking functions to bring users a new driving experience of unperceivedswitching between Cockpit and parking features.`,
        // adv_d_title: 'Powerful AI tool chain',
        // adv_d_desc: `RazorDCX Takla inherits mobile platforms such as mobile phones and tablets, and supports all mainstream AI frameworks
        // `,
        // adv_e_title: 'Flexible ways of cooperation',
        // adv_e_desc: `RazorDCX Takla adopts a design in which algorithm, software and hardware are decoupled independently, and modules are not strongly bound and strongly associated, providing customers with flexible and diverse cooperation modes.`,
      },

      core_property:{
        title:'Core indicators',
        camera_interface:{
          name:'Chip',
          value:'SA8255P'
        },
        display_interface:{
          name:'Typical Features',
          value:`Single chip multi-screen (supports up to 12 screens, up to 8K resolution) \n
Multi-system: Dashboard (Linux/QNX) + Other screens (Android)\n
Intelligent Vision: AVM+DMS/FACE ID+DVR+OMS+CMS\n
Parking: APA/RPA/HPA\n
Connectivity: 1 Bluetooth phone + 7-channel handle\n
HMI: Dynamic 3D HMI interface, immersive experience\n
Gaming: Multiple games running simultaneously`
        },
        sense_input:{
          name:'Computing Power',
          value:`GPU: 1.5TFLOPS \n
CPU: 170kDMIPS \n
AI: 20TOPS`
        },
        ethernet:{
          name:'Key Specifications',
          value:`MIPI：4x 4lane, CSI-2 v2.1 \n
USB：2*USB3.1, 1*USB2.0 \n
PCLe：2x PCIe Gen4 – 1x4 + 1x2 lanes \n
Max Memory Size：36GB \n
LPDDR：LPDDR5 \n
Process Node：5nm`
        }
      
      },

      nav:{
        summarize:'Overview',
        advantage:'Advantage',
        property:'Indicator',
        coopearation:'Contact us'
      }
    },
    congo: {
      name: 'RazorDCX Congo',
      description: "RazorDCX Congo is the latest generation of extremelycost-effective intelligent driving domain control solution,which can meet the needs of low-cost intelligent drivingdomain control.Congo uses Qualcomm's new generationof Snapdragon Ride platform mass production-gradeintelligent driving chip, with a single chip computingpower of 36TOPS/80KDMIPS, which can support bothbasic configuration 5V5R12USS and high-end configuration7V5R12USS access, and reserves multiple CAN/CANFDinterfaces, providing 4-way automotive-grade Ethernet interfaces to meet the sensor access requirements of different levels of intelligent driving.",

      perform_a: 'Extreme high cost-effectiveness',
      perform_b: 'High functional safety',
      perform_c: 'A complete chain of intelligent driving research and development tools',
      perform_d: 'Plenty of functions and application scenarios',      

      advantage: {
        adv_a_title: 'Extreme high cost-effectiveness',
        adv_a_desc: `- Supporting air-cooled heat dissipation design, reducing costs and making single chip prices more economical. The overall plan ishighly cost-effective.`,
        adv_b_title: 'High functional safety',
        adv_b_desc: `- Adhere to the strictest ASIL-D development standards and adopt advanced fail safe and fail operational security architectures.`,
        adv_c_title: 'A complete chain of intelligent driving research and development tools',
        adv_c_desc: `- Including AI deployment, software deployment and Profiling toolchain, data collection and SIL HIL, to meet the needs ofintelligent driving development, debugging, and optimization, reducing difficulty, saving time, and reducing costs for massproduction implementation.`,
        adv_d_title: 'Rich functions and application scenarios',
        adv_d_desc: `- RazorDCX Congo can achieve L2+level basic low-speed parking, highway autonomous driving, and can be extended to autonomous drivingscenarios such as unmanned vending vehicles, mines, and automatic cleaning vehicles.`,       
      },

      core_property:{
        title:'Core indicators',
        camera_interface:{
          name:'Computing',
          value:'36Tops+80kDMIPS (ASIL-B)9k+2.7kDMIPS (ASIL-D)'
        },
        display_interface:{
          name:'Camera',
          value:'FVC: 2x 8M@30fps SVC: 2x 3M@30fps \n RVC: 1x 3M@30fpsAVM: 4x 2M@30fps'
        },
        sense_input:{
          name:'Radar',
          value:'Front Radar x 1, Corner Radars x 4'
        },
        ethernet:{
          name:'USS',
          value:'12 (DSI port * 4) '
        },
        can:{
          name:'Display',
          value:'3 display port'
        },
        gpio:{
          name:'Ethernet',
          value:`Gateway：1*100BASE-T1 \n Reserved：1*100BASE-T1+1*1000BASE-T1`
        },
        arithmetic:{
          name:'CAN',
          value:'CANFD * 6 (1 Channel WakeUp)'
        },
        power_dissipation:{
          name:'IO Port',
          value:"Input IO * 2 \n Output IO * 3"
        },
        ai_driving_level:{
          name:'IMU',
          value:'Internal integrated'
        },
        function_security:{
          name:'Cooling',
          value:'Fan'
        },
        info_security:{
          name:'ADAS Level',
          value:'Structure Road L2.9 \n Driving & Parking Features Combination'
        },
        volume:{
          name:'FuSa',
          value:'Board Level: ASIL – B \n Feature Level: UptoASIL-D（with TC397 chip）'
        },
        tiji: {
          name: "Size",
          value: "≤ 255mm*167mm*70mm",
        }
      },

      nav:{
        summarize:'Overview',
        advantage:'Advantage',
        property:'Indicator',
        coopearation:'Contact us'
      }
    },
    takla: {
      name: 'RazorDCX Takla',
      description: "RazorDCX Takla is a domain controller solution for integrated parking and driving application scenarios launched by ThunderX. It uses Qualcomm's advanced 7nm chip SA8540P to provide a hashrate of 60TOPS 200KDMIPS for the vehicle. Takla provides access capability of up to 8M Pixels of 12-channel camera, reserve multiple CAN/CANFD interfaces, provides 8-way Ethernet interface of vehicle specification level, to meet the requirements of automatic driving for sensor access. It can not only be applied to low-speed parking autopilot, highway autopilot, urban autopilot, but also can be extended to closed park autopilot scenarios such as self-service vehicles, mines, and automatic cleaning vehicles.",

      perform_a: 'Heterogeneous computation',
      perform_b: 'Rich external interfaces',
      perform_c: 'Rich application scenarios',
      perform_d: 'Powerful AI tool chain',
      perform_e: 'Flexible ways of cooperation',

      advantage: {
        adv_a_title: 'Heterogeneous computation',
        adv_a_desc: `RazorDCX Takla provides 60TOPS (INT8) AI computing power, and can be expanded to 720TOPS (INT8), 200K~400kDMIPS SOC computing power, and 4kDMIPS ASIL-D computing power through external AI acceleration chips to meet the requirements of various scenarios of automatic driving
        `,
        adv_b_title: 'Rich external interfaces',
        adv_b_desc: `RazorDCX Takla provides access capability of up to 8M Pixel of 12-channel camera, reserves multiple CAN/CANFD interfaces, provides 8-way Ethernet interface of vehicle specification level, to meet the requirements of automatic driving for sensor access.
        `,
        adv_c_title: 'Rich application scenarios',
        adv_c_desc: `RazorDCX Takla can not only be applied to low-speed parking autopilot, highway autopilot, urban autopilot, but also can be extended to closed park autopilot scenarios such as self-service vehicles, mines, and automatic cleaning vehicles.
        `,
        adv_d_title: 'Powerful AI tool chain',
        adv_d_desc: `RazorDCX Takla inherits mobile platforms such as mobile phones and tablets, and supports all mainstream AI frameworks
        `,
        adv_e_title: 'Flexible ways of cooperation',
        adv_e_desc: `RazorDCX Takla adopts a design in which algorithm, software and hardware are decoupled independently, and modules are not strongly bound and strongly associated, providing customers with flexible and diverse cooperation modes.`,
      },

      core_property:{
        title:'Core indicators',
        camera_interface:{
          name:'Camera interface',
          value:'1x 8M@30fps \n 7x 3M@30fps \n 4x 2M@30fps \n Supporting front view, perimeter view, around view, OMS, DMS'
        },
        display_interface:{
          name:'Display interface',
          value:'1x MAX96789（two-way 1080P video output）\n 1x MAX96717 （Bypass around output）\n 1080p 1080p three-way video output'
        },
        sense_input:{
          name:'Perceptual input',
          value:'Millimeter wave radar x 5, \n ultrasonic x 12, \n laser radar x 3'
        },
        ethernet:{
          name:'Ethernet',
          value:'vehicle specification level:2*1Gb/s + 6*100Mb/s \n Debugging reservation:  1Gb/s'
        },
        can:{
          name:'CAN',
          value:'12-channel CAN-FD, three-way \n three-way wake up (TJA1462. 1145. 1043)'
        },
        gpio:{
          name:'GPIO',
          value:`12+ (mainly used for ultrasonic)`
        },
        arithmetic:{
          name:'HashRate',
          value:'60Tops+200kDMIPS (ASIL-B) \n 4kDMIPS (ASIL-D)'
        },
        power_dissipation:{
          name:'Power consumption',
          value:"Overall < 50W"
        },
        ai_driving_level:{
          name:'Intelligent driving level',
          value:'Closed road L2.9 + Other scenes L2 \n Integrated parking and driving'
        },
        function_security:{
          name:'Functional Security',
          value:'ASIL-D (security chip)'
        },
        info_security:{
          name:'Information Security',
          value:'Reserved national security chip'
        },
        volume:{
          name:'Volume',
          value:'288*188*47'
        }
      },

      nav:{
        summarize:'Overview',
        advantage:'Advantage',
        property:'Indicator',
        coopearation:'Contact us'
      }
    },
    pantanal: {
      name: "RazorDCX Pantanal",
      description:
        "RazorDCX Pantanal is the latest generation of mass-produced intelligent driving domain control solutions by ThunderX. It provides multiple configurations, satisfying the need for intelligent driving domain control for models from entry to flagship level. Pantanal uses Qualcomm's latest generation mass-produced Snapdragon Ride platform intelligent driving chip, with single chip computing power 50-100Tops/230KDMIPS. It simultaneously supports standard package (7V5R12USS) and advanced package (11V5R12USS). It reserves multiple interfaces for CAN/CANFD and provides eight vehicle-grade Ethernet interfaces. Its sensor access capability satisfies the need for autonomous driving of different levels. Pantanal can integrate up to 29 ADAS functions that encompass three major function scenarios: safety, comfort, and parking. It accomplishes HAVP, L2.9 highway autonomous driving, and L3 urban self-driving. It can also be used in closed-park autopilot scenarios such as L4 autonomous vending vehicles, mines, and autonomous cleaning vehicles.",

      perform_a: "Strong Scalability",
      perform_b: "High-level \nFunctional Safety",
      perform_c: "Full Development \nToolchain",
      perform_d: "plenty of functions and \napplication scenarios",

      advantage: {
        adv_a_title: "Strong Scalability",
        adv_a_desc: `Scalable computing power: single chip computing power can increase from 100 Tops to 400 Tops. It supports different sensor configurations and computing power requirements for different algorithms. Scalable hardware: supports different solutions to different chip combinations. \nScalable sensors: supports standard solution(NOA+7V5R12USS for parking), and advanced solution(NOA+ 11V5R12USS). The advanced solution does not need a high definition map.`,

        adv_b_title: "High-level Functional Safety",
        adv_b_desc: `Pantanal follows rigorous ASIL-D development standards. It adopts advanced fail-safe and fail-operational safety architecture to meet the need for autonomous driving. Qualcomm SoC satisfies ASIL-B level with an ASIL-D level safety island: the integrated design of domain controller reaches ASIL-D level.`,

        adv_c_title: "Full Development Toolchain",
        adv_c_desc: `ThunderX provides a full set of domain control toolchains including AI employment, software employment, profiling, data collection, SIL, and HIL. Pantanal satisfies the need for development, debugging, and optimization for intelligent driving. It reduces difficulty, time, cost for achieving mass production.`,

        adv_d_title: "Plenty of functions and application scenarios",
        adv_d_desc: `Pantanal supports safety functions(AEB/FCW/LKA/LDW/ELK/FCTA/ FCTB/RCTA/RCTB/DOW/BSW/LCA/RCW/SCW), comfort functions(ACC /LCC/TJA/ICA/IHBC/TSR/SAS/SCF/TLR/HWA/NOP/TJP, and parking functions(APA/RPA//VPA), totaling three major fields of 29 ADAS functions.  It accomplishes HAVP, L2.9 highway autonomous driving, and L3 urban self-driving. It can also be used in closed-park autopilot scenarios such as L4 autonomous vending vehicles, mines, and autonomous cleaning vehicles.`,
      },

      core_property: {
        title: "Core indicators",
        camera_interface: {
          name: "Camera Port",
          value: `2x 8M@30fps \n 5x 3M@30fps \n 4x 2M@30fps \n Support FVC、SVC、AVC、OMS、DMS`,
        },
        display_interface: {
          name: "Display Port",
          value: "1x MAX96789（2x8540P video output）\n 1x MAX96717 （Bypass surround view camera output）\n 1080p three video outputs",
        },
        sense_input: {
          name: "Perception Input",
          value: "Radar x 5, USS x 12, Lidar x 1",
        },
        ethernet: {
          name: "Ethernet",
          value: "Vehicle level：4*1000BASE-T1+ 4*100BASE-T1 \n Reserved for Debugging：100BASE-TX \n Switch：88Q5192",
        },
        can: {
          name: "CAN",
          value: "12-channel CAN-FD，3-channel wake up \n（TJA1462、1145、1043）",
        },
        gpio: {
          name: "GPIO",
          value: `12+`,
        },
        arithmetic: {
          name: "Computing power",
          value: "100Tops+230kDMIPS (ASIL-B)\n 18k+4kDMIPS (ASIL-D)",
        },
        power_dissipation: {
          name: "Power consumption",
          value: "Entire Device<60W",
        },
        type_of_cooling: {
          name: "Cooling Method",
          value: "Liquid Cooling",
        },
        ai_driving_level: {
          name: "Intelligent Driving \nLevel",
          value: "Enclosed Traffic Zone L2.9+\\Other Scenario L2 \n Pilot & Parking",
        },
        function_security: {
          name: "Functional Safety",
          value: "ASIL-D（security chip）",
        },
        info_security: {
          name: "Information \nSecurity",
          value: "Reserved for State Cryptography chip",
        },
        volume: {
          name: "Volume",
          value: "316*188*48",
        },
      },
      nav: {
        summarize: "Overview",
        advantage: "Advantage",
        property: "Indicator",
        coopearation: "Contact us",
      },
    },
    tarkine: {
      name: "RazorDCX Tarkine",
      description:
        "RazorDCX Tarkine is a new generation MP Cockpit-Driving Fusion domain control solution for ThunderX, and is the industry's first Cockpit-Driving Fusion domain control solution of one SOC. The Qualcomm SOC used in the solution is  Vehicle specification  and its process is 4nm，the solution based on a new Snapdragon Ride Flex platform，which can meet the needs of both cockpit and intelligent driving domains with a single SOC. The RazorDCX Tarkine can achieve 3D HMI, cabin vision, gaming, audio and video entertainment, and interconnection in the cockpit，while also meet the functional requirements of ADAS such as APA and high-speed NOA. It can also provide customized services to satisfy   the needs of OEM from entry-level to flagship level by computational power expansion. It is a cost-effective and technologically leading Cockpit-Driving Fusion domain control solution in the  current market.",

      perform_a: " Plenty of the external interfaces",
      perform_b: "Plenty of functions and \napplication scenarios",
      perform_c: "Extreme high cost-effectiveness ",
      perform_d: "Computing power scheduling mechanism and \nreal-time communication of one SOC ",
      perform_e: "Powerful ecological Toolchain ",
      perform_f: "High-level Functional Safety ",
      perform_g: "High hardware integration and \nsimplified structure ",

      advantage: {
        adv_a_title: "Plenty of the external interfaces",
        adv_a_desc: `- For intenlligent driving: support 11V5R12U (2-way forward view 8M, 4-way circumferential view, 4-way circumferential view, 1-way rear view, 5-way millimeter wave radar, and 12-way ultrasonic radar) access, and reserve 12 ways CAN/CANFD interface, providing 8-way vehicle level Ethernet interface\n- For the cockpit: supports multi-screen interaction, analog power amplifier and A2B digital interface, as well as MOST interface \n- Can integrate gateway for more ECU control`,

        adv_b_title: "Plenty of functions and application scenarios",
        adv_b_desc: `- The HPC Cockpit-Driving Fusion domain controller can support the integration of functions such as IVI, ADAS, Cluster, HUD, AVM, DMS, DVR, etc\n- Supporting intelligent driving on L2++highways and urban areas`,

        adv_c_title: "Extreme high cost-effectiveness",
        adv_c_desc: `- Cost of Domain controller: The Cockpit-Driving Fusion solution has a cost-effectiveness advantage, which combines cost considerations with functional safety design, powerful middleware functions, and efficient tool chain to ensure that the product meets performance and functional requirements while reducing cost \n- Cost of R&D: In the medium to long term, it will lead to cost savings in R&D iterations, upgrades, and maintenance\n- Vehicle cost: Reduce the cost of vehicle BOM (wiring harness, peripheral components); Reduce supplier management costs; Reduce production, after-sales and other costs`,

        adv_d_title: "Computing power scheduling mechanism and real-time communication of one SOC",
        adv_d_desc: `- Dynamic sharing of computing power and storage \n- The middleware of  one SOC can achieve  the real-time communication and deterministic scheduling between multiple domains `,

        adv_e_title: "Powerful ecological Toolchain",
        adv_e_desc: `- The intelligent driving AI tool chain RazorAIKit supports all mainstream AI frameworks such as Pytorch, Onnx, Caffe, TensorFlow, etc. It can share the platform with the Smart cockpit, effectively improving deployment and iteration efficiency, and reducing personnel investment and feedback costs \n- The RazorWareX toolchain conforms to the SOA architecture, which can improve the flexibility, scalability, and reusability of the system, reduce the coupling of the system, and make it easier to maintain and evolve`,

        adv_f_title: "High-level Functional Safety",
        adv_f_desc: `- Follow the rigorous ASIL-D development standards. It adopts advanced fail-safe and fail-operational safety architecture to meet the need for autonomous driving.  Qualcomm SOC of ASIL-B not only includes safety islands, but also peripheral PMICs containing monitoring units, can fully implementing a functional security monitoring mechanism`,

        adv_g_title: "High hardware integration and simplified structure",
        adv_g_desc: `- Structure: High integration, small size, and light weight \n- Heat dissipation: Adopting a new cavity type liquid cooling design, with higher heat dissipation efficiency`,
      },

      core_property: {
        title: "Core indicators",
        camera_interface: {
          name: "Camera Port",
          value: `2x 8M@30fps \n 5x 3M@30fps \n 4x 2M@30fps \n Support FVC、SVC、AVC、OMS、DMS`,
        },
        display_interface: {
          name: "Display Port",
          value: "1x MAX96789（2x8540P video output）\n 1x MAX96717 （Bypass surround view camera output）\n 1080p three video outputs",
        },
        sense_input: {
          name: "Perception Input",
          value: "Radar x 5, USS x 12, Lidar x 1",
        },
        ethernet: {
          name: "Ethernet",
          value: "Vehicle level：4*1000BASE-T1+ 4*100BASE-T1 \n Reserved for Debugging：100BASE-TX \n Switch：88Q5192",
        },
        can: {
          name: "CAN",
          value: "12-channel CAN-FD，3-channel wake up \n（TJA1462、1145、1043）",
        },
        gpio: {
          name: "GPIO",
          value: `12+`,
        },
        arithmetic: {
          name: "Computing power",
          value: "72Tops+230kDMIPS (ASIL-B)",
        },
        power_dissipation: {
          name: "Power consumption",
          value: "Entire Device<60W",
        },
        type_of_cooling: {
          name: "Cooling Method",
          value: "Liquid Cooling",
        },
        ai_driving_level: {
          name: "Intelligent Driving \nLevel",
          value: "Enclosed Traffic Zone L2.9+\\Other Scenario L2 \n Pilot & Parking",
        },
        function_security: {
          name: "Functional Safety",
          value: "ASIL-D（security chip）",
        },
        info_security: {
          name: "Information \nSecurity",
          value: "Reserved for State Cryptography chip",
        },
        volume: {
          name: "Volume",
          value: "343*238*39.5",
        },
      },
      nav: {
        summarize: "Overview",
        advantage: "Advantage",
        property: "Indicator",
        coopearation: "Contact us",
      },
    },

    rwx1: {
      name: 'RazorWareX 1.0',
      description: 'RazorWareX provides multiple services for intelligent driving application algorithms, and configures a high-real-time and high-bandwidth communication backbone network between the chips of the domain controller and the systems of the vehicle end, which fully deploys hardware computing power, shares sensor data, to provide AI algorithm running environment and a highly reliable open software platform for intelligent driving.',

      perform_a: 'Open platform, supporting parallel development of algorithm ecology',
      perform_b: 'Sound platform service software',
      perform_c: 'Heterogeneous RTE interfaces across chips and domains',
      perform_d: 'Time triggered communication backbone network',
      perform_e: 'High functional security',
      perform_f: 'User friendly configuration tool chain and automatic code generation',

      advantage: {
        adv_a_title: 'Open platform, supporting parallel development of algorithm ecology',
        adv_a_desc: `Rationalizing the development process, and simplifying  the complexity of developing intelligent driving system through flexible and configurable middleware modules, standard communication interfaces, and visual development tools
        `,
        adv_b_title: 'Sound platform service software',
        adv_b_desc: `Enabling customers to develop intelligent driving systems with easy verification and high driving safety through a highly reliable functional security platform with consistent calculation results Enabling intelligent driving domain control in terms of system stability, reasonable resource allocation and smooth operation
        `,
        adv_c_title: 'Heterogeneous RTE interfaces across chips and domains',
        adv_c_desc: `Architectural design of multiple time domains, supporting the hosts within a domain controller and internal and external controllers to work in the same time domain. 
        Multi-core heterogeneous architecture design, meeting the big data interaction scenarios between hosts in the domain controller, and support flexible deployment of functional algorithms.  
        `,
        adv_d_title: 'Time triggered communication backbone network',
        adv_d_desc: `Architecture design of time-triggered tasks, customer special scenarios that are compatible with event-triggered and data-triggered tasks
        `,
        adv_e_title: 'High functional security',
        adv_e_desc: `Architecture design taking into account functions and security, supporting the security level target ASIL_D`,
        adv_f_title: `User friendly configuration tool chain and automatic code generation`,
        adv_f_desc: `Architecture design that the tool chain supports the code generation in an all-round way, a complex system development system that meets the requirements definition software, supporting rapid software iteration
        `
      },

      nav:{
        summarize:'Overview',
        advantage:'Advantage',
        coopearation:'Contact us'
      }
    },

    aikit: {
      name: 'RazorAIKit',
      description: 'RazorAIKit is a tool chain provided by ThunderX for the deployment of intelligent driving AI algorithm, which separates the development training and deployment of the model, and focuses on solving the difficulties and problems in the model deployment phase based on Qualcomm Deep Learning inference acceleration framework(QNN). RazorAIKit provides a unified graphical deployment workflow, allowing model deployment to be completed in one click. Version management meets the deployment requirements of single project with multiple models. RazorAIKit also integrates model visualization, multi-platform verification, multi-model performance testing, operator statistics and many other utility tools, which make debugging and optimization convenient and fast.',

      perform_a: 'Unified deployment workflow',
      perform_b: 'Project-level version management',
      perform_c: 'Model visualization',
      perform_d: 'Multi-platform verification',
      perform_e: 'Operator operation analysis',

      advantage: {
        adv_a_title: 'Unified deployment workflow',
        adv_a_desc: `Turning complexity into simplicity, integrating all workflows in the deployment process, providing automatic quantization and verification, to realize one-click deployment
        `,
        adv_b_title: 'Project level version management',
        adv_b_desc: `Performing batch operation according to the project, configuring and deploying algorithm models of different versions and tasks, and provide model validation and performance comparison within the project at the same time.
        `,
        adv_c_title: 'Model visualization',
        adv_c_desc: `Providing model visualization function to facilitate modification and adjustment of model configuration
        `,
        adv_d_title: 'Multi-platform verification',
        adv_d_desc: `Providing x86 running simulator and on-device platform results verification, and multi-platform interfaces
        `,
        adv_e_title: 'Operator operation analysis',
        adv_e_desc: `Visual analysis of operator level performance provides clear comparison data for operator optimization and model deployment and configuration`,
      },

      response_time:'Feedback Time',
      human_investion:'Labor Cost',
      learning_cost:'Training Cost',
      iteration_efficient:'Iteration Efficiency',
      deploy_efficient:'Deployment Efficiency',
      adv_tip:'RazorAIKit is used to deploy the six way visual obstacle and lane line perception algorithm, and the workload is reduced from 10 man-days to 3 man-days.',

      nav:{
        summarize:'Overview',
        advantage:'Advantage',
        coopearation:'Contact us'
      }
    },

    contact_tip: "Contact us for more product information",
    contact_btn: 'Contact us',
    dev_kit: {
      banner_title: "Development Kit",
      banner_subtitle: "Empowering Intelligent Driving Software Development",
      overview_title: "Development Kit Overview",
      overview_desc: "ThunderX Development Kit is a comprehensive solution for intelligent driving software developers and system integrators, aimed at simplifying the development process and improving development efficiency.",
      features_title: "Key Features",
      features: [
        "Comprehensive Software Development Toolset",
        "Cross-Platform Compatibility",
        "Rich Algorithm Library and Reference Implementations",
        "Flexible Customization Options",
        "Comprehensive Technical Support"
      ],
      benefits_title: "Development Kit Advantages",
      benefits: [
        {
          title: "Accelerate Development",
          desc: "Provide standardized tools and modules to significantly shorten development cycles"
        },
        {
          title: "Cost Reduction",
          desc: "Reduce repetitive development work and improve R&D resource utilization"
        },
        {
          title: "High Reliability",
          desc: "Strictly tested components to ensure system stability"
        },
        {
          title: "Flexible Adaptation",
          desc: "Support multiple hardware platforms and software architectures"
        }
      ]
    },
  },

  coopearation:{
    contact_us:'Contact Us',
    business_coperation:'Buiness cooperation',
    resume_delivery:'Resume submassion',
    phone_number:'Number',

    business_consulting:'Contact Us',
    company:'Company',
    name:'Name',
    position:'Position',
    email:'E-mail',
    mobile:'Telephone',
    context:'Content',
    submit:'Submit',
  },


  about_us:{
    banner_title:'ABOUT US',
    banner_tip:'ABOUT US',

    company_profile:'Introduction',
    mission_vision:'Mission & Vision',
    development_course:'History',
    development_center:'R & D Center',
    qualification:'Certification',
    contact_us:'Contact Us',

    introduction:`ThunderX Auto Technology Co., Ltd. (hereinafter referred to as: ThunderX) is a software and hardware platform enterprise invested and established by ThunderSoft, 
focusing on the development of intelligent driving domain controller and high performance computing (HPC)central computer. 
  ThunderX provides innovative and highly decoupled domain control platform, self-developed middleware and complete tool chains for global smart car customers. 
Relying on the leading technology advantage of ThunderSoft in the cockpit software field, 
ThunderX integrates ecological strategic partners from operating system, chip, perception, 
intelligent manufacturing and algorithm field, jointly builds intelligent driving domain control and cross-domain integration products, 
so as to provide customers with a flexible business service mode of multi-category, co-creation and full stack.`,
    vision:'Vision',
    vision_tip:'To make driving smarter and travel experience better',
    mission:'Mission',
    mission_tip:'To create a win-win intelligent driving ecosystem ',
    sense_value:'Values',
    sense_value_tip:'Customer success, leading innovation, people-oriented, embracing changes',

    business_cooperation:'Business Cooperation',
    mobile:'Tel',


    multip_dev_center:'R & D Center',

    department_a:'Shanghai Office',
    department_a_add:'6nd floor, No.26 Baoshi Building, No.487 TianLin Road, Xuhui District, Shanghai, P.R.China',
    
    department_b:'Suzhou Office',
    department_b_add:'Unit 0203, Building A, No.1355 Jinjihu Avenue, Suzhou Industrial Park, Suzhou, Jiangsu, P.R.China '
  },

  join_us:{
    cx_life:{
      label:'Working Environment',
      openness_office:'Open Office Area',
      inhouse_rd:'R&D Laboratory',
      lounge:'Leisure Tea Area',
      reading_corner:'Shared Book Corner'
    },
    cx_culture:{
      label:'Company Culture',
      all_heads_meeting:'All Hands Meeting',
      new_staff_training:'New Employee Training',
      enginner_forum:'Engineer Forum',
      fresh_graduate:'Fresh Graduate Training Program'
    },
    cx_care:{
      label:'Employee Care',
      physical_examination:'Annual Physical Examination',
      organization_activty:'Club Activities',
      holiday_welfare:'Annual Welfare',
      welfare_benifit:'Welfare Allowance',
      flexable_office_hours:'Flexible Office Hours'
    }
  },

  recuritment:{
    count_txt:' positions are open',
    rec_search_placehold:'Search',
    rec_search_address:'Office',
    rec_search_kind:'Job Type',
    reset_condi:'Clear the filter',

    table:{
      position_name:'Job Title',
      position_type:'Job Type',
      department:'Department',
      need_number:'Quantity',
      address:'Office',
      create_time:'Release Time',
    },

    pagination:{
      jump_to:'go to',
      page:'page',
      certain:'GO',
    },

    join_in:'Submit',
    position_responsibility:'Job Description:',
    position_require:'Job Requirements:',

    delivery:'Resume Submission',
    form:{
      name:'Name:',
      gender:'Gender:',
      mobile:'Telephone:',
      email:'E-mail:',
      education:'Education:',
      graduated:'College:',
      profession:'Major:',
      prifle:'Resume:',
      submit:'Submit'
    }
  },

  search:{
    input_placehold:'Search',

    text:'Search',
    result:'Have'
  }
}
