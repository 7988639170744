import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/news/info/:id',
    name: 'NewsInfo',
    component: () => import('../views/NewsInfo.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/join',
    name: 'Join',
    component: () => import('../views/Join.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/join/recruitment/:type',
    name: 'Recruitment',
    component: () => import('../views/join/Recruitment.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/recruitment/detail',
    name: 'JoinDetail',
    component: () => import('../views/join/Detail.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/pro/razorDCX',
    name: 'Razordcx',
    component: () => import('../views/pro/RazorDCX.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/pro/razorWareX',
    name: 'Razordwx',
    component: () => import('../views/pro/RazorWareX.vue'),
    meta:{
      keepAlive:false
    }
  },
  //  张伟20240516
  {
    path: '/pro/razorDCX-tongass',
    name: 'RazorDCXTongass',
    component: () => import('../views/pro/RazorDCXTongass.vue'),
    meta:{
      keepAlive:false
    }
  },
  //  张伟20240515
  {
    path: '/pro/razorDCX-congo',
    name: 'RazorDCXCongo',
    component: () => import('../views/pro/RazorDCXCongo.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/pro/razorDCX-takla',
    name: 'RazorDCXTakla',
    component: () => import('../views/pro/RazorDCXTakla.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/pro/razorDCX-pantanal',
    name: 'RazorDCXPantanal',
    component: () => import('../views/pro/RazorDCXPantanal.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/pro/razorDCX-tarkine',
    name: 'RazorDCXTarkine',
    component: () => import('../views/pro/RazorDCXTarkine.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/pro/razorWareX-version1',
    name:'RazorWareXVersion',
    component: () => import('../views/pro/RazorWareXVersion.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/pro/razoraikit',
    name:'RazorAIKit',
    component: () => import('../views/pro/RazorAIKit.vue'),
    meta:{
      keepAlive:false
    }
  },
  // 新增开发套件路由
  {
    path:'/pro/dev-kit',
    name:'DevKit',
    component: () => import('../views/pro/DevKit.vue'),
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/search/:value',
    name:'RazorAIKit',
    component: () => import('../views/Search.vue'),
    meta:{
      keepAlive:false
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
